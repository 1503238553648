<template>
  <ul class="binding-list">
    <li>
      <div>
        账户密码<br>
        <p>当前密码强度：{{data.strength}}</p>
      </div>
      <div>
        <span @click="showModal(1)">修改</span>
      </div>
    </li>
    <li>
      <div>
        密保手机<br>
        <p>已绑定手机：{{dataSource.phone}}</p>
      </div>
      <div>
        <span @click="showModal(0)">修改</span>
      </div>
    </li>
  </ul>
  <a-modal v-model:visible="dataSource.visible" :title="dataSource.flag?'修改密码':'修改密保手机'" :footer="null" >
    <template v-if="dataSource.flag">
    <my-recover ref="one" :id="1" :closeFun="handleOk" :mobile="dataSource.mobile"/>
    </template>
    <template v-else>
    <my-changing-phone ref="two" :closeFun="handleOk" :mobile="dataSource.mobile"/>
    </template>
  </a-modal>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue';
import { getUserInfo } from '../../api';
import MyRecover from '../user/recover.vue';
import MyChangingPhone from "../user/changingPhone.vue";
const data = 
  {
    strength: '强',
  }
export default defineComponent({
  components:{
    MyRecover,
    MyChangingPhone
  },
  setup() {
    const dataSource = ref({
      visible:false,
      flag:0,
      mobile:Number,
      phone:''
    })
    const one = ref()
    const two = ref()
    const showModal = (id) => {
      dataSource.value.flag= id
      dataSource.value.visible = true;
      if (id&&one.value) {
        one.value.removes()
      }else if(id==0&&two.value){
        two.value.removes()
      }
    };

    const handleOk = id => {
      dataSource.value.visible = false;
      init()
    };
    const init = async ()=>{
      let id = window.sessionStorage.getItem('user-id')
      const res = await getUserInfo(id)
      let { mobile }=res.userInfo
      dataSource.value.mobile = mobile
      let arr = mobile.split('')
      arr.splice(3,4,'****')
      dataSource.value.phone = arr.join('')
    }
    onMounted(init)
    
    return {
      data,
      dataSource,
      one,
      two,
      showModal,
      handleOk,
    };
  },
});
</script>

<style scoped lang='less'>
  .binding-list{
    list-style: none;
    padding-left: 0;
    color: #000000A6;
  }
  .binding-list p{
    color: #00000073;
    margin-bottom: 0;
  }
  .binding-list li{
    border-bottom: 1px solid #e8e8e8;
    padding: 14px 0;
    display: flex;
    justify-content: space-between;
  }
  .binding-list div{
    padding-right: 10px;
    vertical-align: middle;
  }
  .binding-list div span{
    line-height: 44px;
    cursor: pointer;
    color: @link-color;
  }
</style>
