<template>
  <div class="recover">
    <div :style="{ transform: `translateX(-${amendCode.offset}%)` }">
      <a-form
        name="custom-validation"
        ref="formRef2"
        :model="formState"
        :rules="rules"
        v-bind="layout"
        :labelCol="{span: 6, offset: 0}"
        @finish="handleFinish"
        @finishFailed="handleFinishFailed"
      >
        <a-form-item has-feedback label="手机号" name="phone">
          <a-input v-model:value="formState.phone" disabled/>
        </a-form-item>
        <a-form-item has-feedback label="验证码" name="code">
          <a-input-group >
            <a-row :gutter="24">
              <a-col :span="16">
                <a-input v-model:value="formState.phoneCode" size="default"/>
              </a-col>
              <a-col :span="8">
                <a-button :disabled="amendCode.phoneFlag?true:amendCode.phoneNumFlag?true:false" type="primary" @click="checkPhoneCode" block>{{amendCode.phoneNumFlag?amendCode.phoneNum:'发送'}}</a-button>
              </a-col>
            </a-row>
          </a-input-group>
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 10, offset: 7 }" style="text-align:center">
          <a-button type="primary" html-type="submit">下一步</a-button>
        </a-form-item>
      </a-form>
      <a-form
        name="custom-validation"
        ref="formRef3"
        :model="formState"
        :rules="rules"
        v-bind="layout"
        :labelCol="{span: 6, offset: 0}"
        @finish="handleFinish1"
        @finishFailed="handleFinishFailed"
      >
        <a-form-item has-feedback label="新绑定手机号" name="mobile">
          <a-input v-model:value="formState.mobile" />
        </a-form-item>
        <a-form-item has-feedback label="验证码" name="code">
          <a-input-group >
            <a-row :gutter="24">
              <a-col :span="16">
                <a-input v-model:value="formState.code" size="default"/>
              </a-col>
              <a-col :span="8">
                <a-button :disabled="amendCode.mobileFlag?true:amendCode.mobileNumFlag?true:false" type="primary" @click="checkCode" block>{{amendCode.mobileNumFlag?amendCode.mobileNum:'发送'}}</a-button>
              </a-col>
            </a-row>
          </a-input-group>
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 10, offset: 7 }" style="text-align:center">
          <a-button @click="lastStep" >上一步</a-button>
          <a-button style="margin-left:10px" type="primary" html-type="submit">确定</a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>
<script>
import { message } from 'ant-design-vue';
import { defineComponent, reactive, ref, onMounted, watch } from 'vue'
import { ResetPass, regCode, regResetCode, register } from '../../api';
export default defineComponent({
  props:{
    mobile:Number,
    closeFun:Function
  },
  setup (props) {
    const formRef2 = ref()
    const formRef3 = ref()
    const formState = reactive({
      phone: '',
      phoneCode:'',
      mobile:'',
      code:'',
      id:0,
    })
    const amendCode = ref({
      phoneFlag:true,
      phoneNum:60,
      phoneNumFlag:false,
      sendPhoneFlag:false, // 检验手机号是否存在
      mobileFlag:true,
      mobileNum:60,
      mobileNumFlag:false,
      sendMobileFlag:false, // 检验手机号是否重复
      offset:0, // 偏移量
    })
    let phoneTimes = 0
    let mobileTimes = 0
    const checkMobile = async (rule, value) => {
      if (amendCode.value.sendMobileFlag) {
        amendCode.value.sendMobileFlag = false
        return Promise.reject(new Error('手机号已注册！'))
      }
      if (!value) {
        amendCode.value.mobileFlag=true
        return Promise.reject(new Error('请输入手机号'))
      }
      const patt = /1(3[0-9]|4[0,1,4-9]|5[0-3,5-9]|6[2,5-7]|7[0-8]|8[0-9]|9[0-3,5-9])\d{8}/
      if (patt.test(value) && value.length > 10) {
        amendCode.value.mobileFlag=false
        return Promise.resolve()
      } else {
        amendCode.value.mobileFlag=true
        return Promise.reject(new Error('请输入正确的手机号'))
      }
    }
    const checkPhone = async (rule, value) => {
      if (amendCode.value.sendPhoneFlag) {
        amendCode.value.sendPhoneFlag = false
        return Promise.reject(new Error('手机号未注册！'))
      }
      if (!value) {
        amendCode.value.phoneFlag=true
        return Promise.reject(new Error('请输入手机号'))
      }
      const patt = /1(3[0-9]|4[0,1,4-9]|5[0-3,5-9]|6[2,5-7]|7[0-8]|8[0-9]|9[0-3,5-9])\d{8}/
      if (patt.test(value) && value.length > 10) {
        amendCode.value.phoneFlag=false
        return Promise.resolve()
      } else {
        amendCode.value.phoneFlag=true
        return Promise.reject(new Error('请输入正确的手机号'))
      }
    }
    const rules = {
      mobile: [
        {
          validator: checkMobile,
          trigger: 'change'
        }
      ],
      phone: [
        {
          validator: checkPhone,
          trigger: 'change'
        }
      ],
    }
    const layout = {
      labelCol: {
        span: 4
      },
      wrapperCol: {
        span: 14
      }
    }

    // 旧账号发送校验账号与验证码
    const handleFinish = async (values) => {
      const {phone, phoneCode} = formState
      const res = await ResetPass({ mobile:phone, sendCode:phoneCode })
      if (res.msg == '该手机号不存在') {
        message.warn('验证码错误！')
        return
      }
      formState.phoneCode = ''
      amendCode.value.phoneNum = 60
      amendCode.value.phoneFlag=false
      amendCode.value.phoneNumFlag=false
      clearInterval(phoneTimes)
      formState.id = res.data.id
      amendCode.value.offset = 50
    }
    // 新账号修改绑定手机号
    const handleFinish1 = async (values) => {
      const {id, mobile} = formState
      const res = await register({ id, mobile })
      message.success(res.msg)
      setTimeout(()=>{
        formRef3.value.resetFields()
        props.closeFun()
        formState.code = ''
        amendCode.value.offset = 0
        amendCode.value.mobileNum = 60
        amendCode.value.mobileFlag=false
        amendCode.value.mobileNumFlag=false
        clearInterval(mobileTimes)
      },500)
      
    }

    const handleFinishFailed = (errors) => {
    }
    // 旧账号验证码校验
    const checkPhoneCode = async()=>{
      amendCode.value.phoneFlag=true
      amendCode.value.phoneNumFlag=true
      phoneTimes = setInterval(()=>{
        amendCode.value.phoneNum--
        if (amendCode.value.phoneNum == 0) {
          amendCode.value.phoneNum = 60
          amendCode.value.phoneFlag=false
          amendCode.value.phoneNumFlag=false
          clearInterval(phoneTimes)
        }
      },1000)
      const res = await regResetCode(formState.phone)
      if (res.msg == '该手机号不存在') {
        amendCode.value.sendPhoneFlag = true
        amendCode.value.phoneNum = 60
        amendCode.value.phoneFlag=false
        amendCode.value.phoneNumFlag=false
        clearInterval(phoneTimes)
        formRef2.value.validateFields('phone')
      }
    }
    // 新账号验证码校验
    const checkCode = async()=>{

      amendCode.value.mobileFlag=true
      amendCode.value.mobileNumFlag=true
      mobileTimes = setInterval(()=>{
        amendCode.value.mobileNum--
        if (amendCode.value.mobileNum == 0) {
          amendCode.value.mobileNum = 60
          amendCode.value.mobileFlag=false
          amendCode.value.mobileNumFlag=false
          clearInterval(mobileTimes)
        }
      },1000)
      const res = await regCode(formState.mobile)
      if (res.code == '该电话号已注册') {
        amendCode.value.sendMobileFlag = true
        amendCode.value.mobileNum = 60
        amendCode.value.mobileFlag=false
        amendCode.value.mobileNumFlag=false
        clearInterval(mobileTimes)
        formRef3.value.validateFields('mobile')
      }
    }
    // 返回上一步改值、重置
    const lastStep = () =>{
      amendCode.value.offset = 0
      formRef3.value.resetFields()
    }
    // 父组件调用重置
    const removes = ()=>{
      amendCode.value.offset = 0
      formState.phoneCode = ''
      amendCode.value.sendPhoneFlag = false
      amendCode.value.phoneNum = 60
      amendCode.value.phoneFlag=false
      amendCode.value.phoneNumFlag=false
      clearInterval(phoneTimes)
      formState.code = ''
      amendCode.value.sendMobileFlag = false
      amendCode.value.mobileNum = 60
      amendCode.value.mobileFlag=false
      amendCode.value.mobileNumFlag=false
      clearInterval(mobileTimes)
    }
    
    onMounted(()=>{
      if (props.mobile) {
        formState.phone=props.mobile
        formRef2.value.validateFields('phone')
      }
    })
    watch(
      props,
      (val)=>{
        formState.phone=val.mobile
        formRef2.value.validateFields('phone')
      }
    )
    return {
      formRef2,
      formRef3,
      formState,
      rules,
      layout,
      handleFinishFailed,
      handleFinish,
      handleFinish1,
      checkCode,
      checkPhoneCode,
      amendCode,
      lastStep,
      removes,
    }
  }
})
</script>

<style lang="less" scoped>
.recover{
  min-width: 320px;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  overflow: hidden;
}
.recover>div{
  width: 200%;
  display: flex;
  transition: all .5s;
}
.recover form{
  width: 50%;
}
</style>